import React, { FC, useEffect, useRef } from "react";
import { ContentWrap, Logo, LegalContent } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import IntroLogo from "../../assets/images/intro_logo_dark.svg";

const Legal: FC = () => {
  const ContentWrapRef = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          ContentWrapRef?.current || null,
          0.75,
          { opacity: 0, y: 35 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  }, []);
  return (
    <ContentWrap ref={ContentWrapRef}>
      <Logo to={'/'}><img src={IntroLogo} alt="" /></Logo>
      <LegalContent>
        <h2 className={'heading fs-l'}>LEGAL DISCLAIMER</h2>
        <p className={'reg-text'}>The Ritz-Carlton Residences, Pompano Beach are not owned, developed, or sold by The Ritz-Carlton Hotel Company, L.L.C., or its affiliates (“Ritz-Carlton”). 1380 Ocean Associates, LLC uses The Ritz-Carlton marks under a license from Ritz-Carlton, which has not confirmed the accuracy of any of the statements or representations made herein.</p>
        <p className={'reg-text'}>ORAL REPRESENTATIONS CANNOT BE RELIED UPON AS CORRECTLY STATING REPRESENTATIONS OF THE DEVELOPER. FOR CORRECT REPRESENTATIONS, MAKE REFERENCE TO THE DOCUMENTS THAT ARE REQUIRED BY SECTION 718.503, FLORIDA STATUTES, TO BE FURNISHED BY A DEVELOPER TO A BUYER OR LESSEE. The Developer is 1380 Ocean Associates, LLC which has a right to use the trademark names and logos of Fortune International Group and Oak Capital. This is not an offer to sell, or solicitation of offers to buy, in states where such offer or solicitation cannot be made. The rendering contained herein is an artist impression, conceptual interpretation, proposed only and merely intended as illustration. All artist renderings are for illustrative purposes only and are subject to change without notification. No guarantee is made that the described features, services, amenities, or facilities will be available or built. The developer reserves the right to make any modifications, revisions, or withdrawals at its sole discretion and without prior notice. All improvements, design, and construction are subject to first obtaining permits and approvals for same by the relevant authorities.</p>
      </LegalContent>
    </ContentWrap>
  );
};
export default Legal;
