import * as React from "react";
import { seoData } from "../data/seo";
import Seo from "../components/seo";
import Footer from "../components/footer";
import Legal from "../components/legal";

const PressPage = () => {
  return (
    <main>
      <Seo page={seoData.legal} />
      <Legal />
      <Footer />
    </main>
  );
};

export default PressPage;
