import styled from "styled-components";
import {Link} from "gatsby";
import {mediaMax} from "../../helpers/MediaQueries";
export const ContentWrap = styled.div`
  padding: 10vh 4% 6%;
`;
export const Logo = styled(Link)`
  display: block;
  max-width: 280px;
  width: 100%;
  margin: 0 auto 10vh;
  ${mediaMax.tablet`
    max-width: 265px;
  `}
`;
export const LegalContent = styled.div`
  text-align: left;
  max-width: 700px;
  margin: 0 auto;
  ${mediaMax.tablet`
    padding: 12% 5.2% 0;
  `};
  h2 {
    padding-bottom: 34px;
    text-align: center;
  }
  p{
    padding-bottom: 22px;
  }
`;